import Link from "next/link";

export default function Custom404() {
  return (
    <main className="flex items-center justify-center">
      <div className="flex flex-col items-center">
        <h1 className="text-center">Página não encontrada :(</h1>
        <Link href="/">
          <a>Ir para a página inicial</a>
        </Link>
      </div>
    </main>
  );
}
